import type { RouteRecordRaw } from 'vue-router';

import developerToolsGuard from '@/guards/developerToolsGuard';
import { Route } from '@/types';

export default [
  {
    path: '/addons',
    name: Route.DeveloperTools.BASE_PATH,
    meta: {
      hideTitle: true,
      breadcrumbs: null,
      hideBackButton: true,
      title: 'Add-ons',
      wrapperName: 'Add-ons',
    },
    component: () => import('@/views/DeveloperTools/DeveloperToolsWrapper.vue'),
    beforeEnter: developerToolsGuard,
    children: [
      {
        path: 'dashboard',
        name: Route.DeveloperTools.DASHBOARD,
        component: () =>
          import('@/views/DeveloperTools/DeveloperToolsDashboard.vue'),
        children: [
          {
            path: 'presets',
            name: Route.DeveloperTools.PRESETS,
            meta: {
              createRoute: Route.DeveloperTools.PRESET_CREATE,
              editRoute: Route.DeveloperTools.PRESET_EDIT,
            },
            component: () =>
              import(
                '@/views/DeveloperTools/WordPressPresets/WordPressPresets.vue'
              ),
          },
          {
            path: 'ownership-transfer',
            name: Route.DeveloperTools.OWNERSHIP_TRANSFER,
            component: () =>
              import(
                '@/views/DeveloperTools/OwnershipTransfer/OwnershipTransfer.vue'
              ),
          },
          {
            path: 'reports',
            name: Route.DeveloperTools.REPORTS,
            component: () =>
              import('@/views/DeveloperTools/Reports/ReportsDashboard.vue'),
          },
          {
            path: 'monitoring',
            name: Route.DeveloperTools.MONITORING,
            component: () =>
              import('@/views/DeveloperTools/Monitoring/Monitoring.vue'),
          },
        ],
      },
      {
        path: 'reports',
        redirect: { name: Route.DeveloperTools.REPORTS },
        children: [
          {
            path: 'create',
            name: Route.DeveloperTools.CREATE_REPORT,
            meta: {
              hideBackButton: false,
              title: 'Create report',
              breadcrumbs: false,
              backArrowPath: {
                name: Route.DeveloperTools.REPORTS,
              },
            },

            component: () =>
              import('@/views/DeveloperTools/Reports/CreateReportLayout.vue'),
          },
          {
            path: 'edit-schedule/:id',
            name: Route.DeveloperTools.EDIT_REPORT_SCHEDULE,
            meta: {
              hideBackButton: false,
              title: 'Create report',
              breadcrumbs: false,
              backArrowPath: {
                name: Route.DeveloperTools.REPORTS,
                query: { tab: 'schedules' },
              },
            },

            component: () =>
              import('@/views/DeveloperTools/Reports/CreateReportLayout.vue'),
          },
        ],
      },
      {
        path: 'presets/create',
        meta: {
          title: 'Create preset',
          hideTitle: false,
          showSubheader: true,
          breadcrumbs: false,
          hideBackButton: false,
          backArrowPath: {
            name: Route.DeveloperTools.PRESETS,
          },
          previewRoute: Route.DeveloperTools.PRESET_PREVIEW,
        },
        name: Route.DeveloperTools.PRESET_CREATE,
        component: () =>
          import(
            '@/views/DeveloperTools/WordPressPresets/WordPressPresetSetup.vue'
          ),
      },
      {
        path: 'presets/edit/:presetId',
        name: Route.DeveloperTools.PRESET_EDIT,
        meta: {
          title: 'Edit preset',
          hideTitle: false,
          showSubheader: true,
          breadcrumbs: false,
          hideBackButton: false,
          backArrowPath: {
            name: Route.DeveloperTools.PRESETS,
          },
          previewRoute: Route.DeveloperTools.PRESET_PREVIEW,
        },
        component: () =>
          import(
            '@/views/DeveloperTools/WordPressPresets/WordPressPresetSetup.vue'
          ),
      },
      {
        path: 'subscriptions',
        name: Route.DeveloperTools.SUBSCRIPTIONS,
        component: () =>
          import(
            '@/views/DeveloperTools/Subscriptions/DeveloperToolsSubscriptions.vue'
          ),
      },

      {
        path: 'upgrade/:id',
        name: Route.DeveloperTools.UPGRADE,
        meta: {
          hideBackButton: false,
          title: 'Upgrade your add-ons subscription',
          hideTitle: false,
        },
        component: () =>
          import('@/views/DeveloperTools/Upgrade/DeveloperToolsUpgrade.vue'),
      },
      {
        path: 'assign/:subscriptionId?',
        name: Route.DeveloperTools.ASSIGN_WEBSITE,
        component: () =>
          import(
            '@/views/DeveloperTools/WebsiteAssignment/WebsiteAssignment.vue'
          ),
      },
      {
        path: 'purchase/:plan?',
        name: Route.DeveloperTools.PURCHASE,
        meta: {
          hideTitle: false,
          title: 'Add-ons',
        },
        component: () =>
          import('@/views/DeveloperTools/Landing/DeveloperToolsLanding.vue'),
      },
    ],
  },
  {
    path: '/addons/presets/template-preview/:slug',
    name: Route.DeveloperTools.PRESET_PREVIEW,
    meta: {
      title: 'Preview Template',
      header: false,
      showSubheader: false,
      fullHeight: true,
      fullscreen: true,
      createRoute: Route.DeveloperTools.PRESET_CREATE,
      editRoute: Route.DeveloperTools.PRESET_EDIT,
    },
    component: () =>
      import(
        '@/views/DeveloperTools/WordPressPresets/WordPressThemePreview.vue'
      ),
  },
] as RouteRecordRaw[];
