import { computed } from 'vue';
import { useRoute } from 'vue-router';

import { useCookies } from '@/composables/useCookies';
import { useResourceFilters } from '@/composables/useResourceFilters';
import { useResourceMapper } from '@/composables/useResourceMapper';
import { useDeveloperToolsIntroductionStore } from '@/stores/developerTools/developerToolsIntroductionStore';
import { useHotspotStore } from '@/stores/hotspotStore';
import { useProfileStore } from '@/stores/profile/profileStore';
import { useWordpressStore } from '@/stores/wordpressStore';
import { Cookie, Route } from '@/types';

export const useDeveloperTools = () => {
  const { isCookieValueTruthyNumber: isDeveloperToolsCookieEnabled } =
    useCookies(Cookie.ADDONS_ENABLED);
  const route = useRoute();
  const developerToolsIntroductionStore = useDeveloperToolsIntroductionStore();
  const { activeDeveloperToolsResources } = useResourceMapper();
  const { hasCloudHosting, hasDeveloperTools } = useResourceFilters();
  const profileStore = useProfileStore();
  const hotspotStore = useHotspotStore();
  const wordpressStore = useWordpressStore();

  const isDeveloperToolsEnabled = computed(
    () =>
      isDeveloperToolsCookieEnabled.value ||
      (hasCloudHosting.value &&
        wordpressStore.hasManagedAndOwnedWordpressWebsites),
  );

  const hasDeveloperToolsEnabledThroughCloudPlan = computed(
    () =>
      isDeveloperToolsEnabled.value &&
      hasCloudHosting.value &&
      wordpressStore.hasManagedAndOwnedWordpressWebsites,
  );

  const canManageDeveloperToolsSubscriptions = computed(
    () => !profileStore.isAccessManager && !hasCloudHosting.value,
  );

  const hasAccessToAllWebsites = computed(
    () =>
      activeDeveloperToolsResources.value.some(
        ({ config }) => config?.tools?.[0]?.maxWebsiteQuantity === -1,
      ) || hasCloudHosting.value,
  );

  const isEligibleForTrial = computed(
    () => !hasDeveloperTools.value && !hasCloudHosting.value,
  );

  const hasAccessToDeveloperToolsDashboard = computed(
    () =>
      isDeveloperToolsEnabled.value &&
      (activeDeveloperToolsResources.value.length > 0 || hasCloudHosting.value),
  );

  const developerToolsRoute = computed(() => {
    if (!hasAccessToDeveloperToolsDashboard.value) {
      return Route.DeveloperTools.PURCHASE;
    }

    const isInDashboard = route.matched.some(
      ({ name }) => name === Route.DeveloperTools.DASHBOARD,
    );
    const currentRouteName = route.name ?? undefined;

    if (!isInDashboard) {
      return Route.DeveloperTools.DASHBOARD;
    }

    return currentRouteName;
  });

  const isDeveloperToolsPopoverActive = computed(
    () =>
      developerToolsIntroductionStore.isIntroductionPopoverActive &&
      !!hotspotStore.hotspots.navigationHotspot.isClosed,
  );

  return {
    hasAccessToAllWebsites,
    canManageDeveloperToolsSubscriptions,
    isDeveloperToolsEnabled,
    isEligibleForTrial,
    hasAccessToDeveloperToolsDashboard,
    developerToolsRoute,
    hasDeveloperToolsEnabledThroughCloudPlan,
    isDeveloperToolsPopoverActive,
  };
};
