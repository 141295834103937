import { watchDebounced } from '@vueuse/core';
import { difference } from 'lodash';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';

import { useProfileStore } from '@/stores/profile/profileStore';
import {
  GOOGLE_ANALYTICS_EVENTS,
  Route,
  VPS_PURCHASE_STEP,
  type CatalogItem,
  type Currency,
  type HostingPlan,
  type PlanPricingDetails,
  type PlanWithPricingDetailsAndPosition,
} from '@/types';
import { convertToEur } from '@/utils/helpers/currencyHelpers';
import { setDataLayer } from '@/utils/services/google';

const EMAIL_PURCHASE_ROUTES = [
  Route.Email.UPSELL,
  Route.Email.CHOOSE_PLAN,
  Route.Email.EMAILS,
] as string[];

const ROUTES_TO_SEND_GTM = [
  Route.Base.BUY_HOSTING,
  Route.Vps.VPS_PLANS,
  Route.Base.BUY_VPS,
  VPS_PURCHASE_STEP.GET_PLAN,
  ...EMAIL_PURCHASE_ROUTES,
] as string[];

interface IGoogleAnalyticsPurchaseEvents {
  subCategories?: string[];
}

export const useGoogleAnalyticsPurchaseEvents = (
  props: IGoogleAnalyticsPurchaseEvents,
) => {
  const subCategories = props?.subCategories || [];

  const profileStore = useProfileStore();
  const route = useRoute();

  const plansToBeSentToGtmMap = ref<
    Record<string, PlanWithPricingDetailsAndPosition>
  >({});
  const alreadySentPlansKeys = ref<string[]>([]);

  const path = computed(() => {
    if (EMAIL_PURCHASE_ROUTES.includes(route.name as string)) {
      return route.path.split('/')[1];
    }

    return route.path
      .substring(1, route.path.length)
      .replaceAll('/', '_')
      .replaceAll('-', '_');
  });

  const shouldSendGAEvents = computed(() =>
    ROUTES_TO_SEND_GTM.includes(route.name as string),
  );

  const brandDomain = computed(() => profileStore.account?.brand?.domain ?? '');

  const getItemVariant = (catalogItem: CatalogItem | HostingPlan): string => {
    const idSplit = catalogItem.id.split('-');
    idSplit.reverse();

    return `${catalogItem.id} - ${idSplit[0]}`;
  };

  const getItemListId = (subCategories: string[]): string => {
    if (!subCategories.length) {
      return '';
    }

    if (subCategories.length === 1) {
      return `hpanel_${path.value}_pricing_table-${subCategories[0]}`;
    }

    return `hpanel_${path.value}_pricing_table-${subCategories.join(
      '_and_',
    )}_grouped`;
  };

  const toUppercaseFirstLetter = (word: string): string =>
    word.charAt(0).toUpperCase() + word.slice(1);

  const getItemListName = (subCategories: string[]): string => {
    if (!subCategories.length) {
      return '';
    }

    const formattedPath = path.value
      .split('_')
      .map(toUppercaseFirstLetter)
      .join(' ')
      .trim();
    const formattedSubCategories = subCategories.map(toUppercaseFirstLetter);

    if (subCategories.length === 1) {
      return `hPanel ${formattedSubCategories[0]} ${formattedPath} Pricing Table`;
    }

    return `hPanel ${formattedSubCategories.join(
      ' And ',
    )} Grouped ${formattedPath} Pricing Table`;
  };

  const getListNameAndId = (subCategories: string[]) => ({
    itemListId: getItemListId(subCategories),
    itemListName: getItemListName(subCategories),
  });

  const mapEcommerceEventItem = (
    item: PlanWithPricingDetailsAndPosition,
    itemListId: string,
    itemListName: string,
  ) => {
    const { pricingDetails } = item;

    const formattedPrice = convertToEur(
      pricingDetails.price,
      item.prices[0].currencyCode as Currency.Code,
    );
    const formattedRenewalPrice = convertToEur(
      pricingDetails.renewalPrice,
      item.prices[0].currencyCode as Currency.Code,
    );

    return {
      item_id: item.slug,
      item_name: item.externalName,
      coupon: pricingDetails?.coupon ?? '',
      index: item.position,
      price: formattedPrice.amount,
      discount: pricingDetails.discountPercentage,
      item_brand: brandDomain.value,
      item_category: item.category,
      item_category2: item.subcategory,
      item_category3: pricingDetails.period,
      item_category4: formattedRenewalPrice.amount,
      item_list_id: itemListId,
      item_list_name: itemListName,
      quantity: pricingDetails?.quantity ?? 1,
      item_variant: getItemVariant(item),
      currency: formattedPrice.currency,
    };
  };

  const getGAViewItemListEvent = (
    items: PlanWithPricingDetailsAndPosition[],
  ) => {
    const { itemListId, itemListName } = getListNameAndId([
      ...new Set(items.map((item) => item.subcategory)),
    ]);

    return {
      event: GOOGLE_ANALYTICS_EVENTS.VIEW_ITEM_LIST,
      hostname: brandDomain.value,
      item_list_id: itemListId,
      item_list_name: itemListName,
      ecommerce: {
        items: items.map((item) =>
          mapEcommerceEventItem(item, itemListId, itemListName),
        ),
      },
    };
  };

  const getGAViewItemEvent = (item: PlanWithPricingDetailsAndPosition) => {
    const { itemListId, itemListName } = getListNameAndId(subCategories);

    const { pricingDetails } = item;
    const formattedPrice = convertToEur(
      pricingDetails.price,
      item.prices[0].currencyCode as Currency.Code,
    );

    return {
      event: GOOGLE_ANALYTICS_EVENTS.VIEW_ITEM,
      hostname: brandDomain.value,
      currency: formattedPrice.currency,
      value: formattedPrice.amount,
      ecommerce: {
        items: [mapEcommerceEventItem(item, itemListId, itemListName)],
      },
    };
  };

  const getGAAddToCartEvent = (items: PlanWithPricingDetailsAndPosition) => ({
    ...getGAViewItemEvent(items),
    event: GOOGLE_ANALYTICS_EVENTS.ADD_TO_CART,
  });

  const getGAViewCartEvent = (item: PlanWithPricingDetailsAndPosition) => ({
    ...getGAViewItemEvent(item),
    event: GOOGLE_ANALYTICS_EVENTS.VIEW_CART,
  });

  const getGABeginCheckoutEvent = (
    item: PlanWithPricingDetailsAndPosition,
  ) => ({
    ...getGAViewItemEvent(item),
    coupon: item.pricingDetails?.coupon ?? '',
    event: GOOGLE_ANALYTICS_EVENTS.BEGIN_CHECKOUT,
  });

  const getGASelectEvent = (item: PlanWithPricingDetailsAndPosition) => ({
    ...getGAViewItemEvent(item),
    event: GOOGLE_ANALYTICS_EVENTS.SELECT_ITEM,
  });

  const sendGAEvent = (eventData: Object) => {
    if (!shouldSendGAEvents.value) {
      return;
    }

    setDataLayer({ ecommerce: null });
    setDataLayer(eventData);
  };

  const sendItemShownEvents = (
    plan: CatalogItem | HostingPlan,
    pricingDetails: PlanPricingDetails,
    position: number,
  ) => {
    if (plansToBeSentToGtmMap.value[position]) {
      return;
    }

    plansToBeSentToGtmMap.value[position] = {
      ...plan,
      pricingDetails,
      position,
    };
  };

  const sendGoogleAnalyticsEventsBatch = () => {
    const planIndexesToBeSent = difference(
      Object.keys(plansToBeSentToGtmMap.value),
      alreadySentPlansKeys.value,
    );

    if (planIndexesToBeSent.length === 0) {
      return;
    }

    const plansToSend = [...planIndexesToBeSent].map(
      (position) => plansToBeSentToGtmMap.value[position],
    );

    sendGAEvent(getGAViewItemListEvent(plansToSend));

    plansToSend.forEach((plan) => {
      alreadySentPlansKeys.value.push(plan.position.toString());
      sendGAEvent(getGAViewItemEvent(plan));
    });
  };

  const sendGAAddToCartEvent = (position: string) => {
    const plan = plansToBeSentToGtmMap.value[position];

    if (!plan) {
      return;
    }

    sendGAEvent(getGAAddToCartEvent(plan));
  };

  const sendGASelectEvent = (position: string) => {
    const plan = plansToBeSentToGtmMap.value[position];

    if (!plan) {
      return;
    }

    sendGAEvent(getGASelectEvent(plan));
  };

  const sendGAViewCartEvent = (plan: PlanWithPricingDetailsAndPosition) => {
    sendGAEvent(getGAViewCartEvent(plan));
  };

  const sendGABeginCheckoutEvent = (
    plan: PlanWithPricingDetailsAndPosition,
  ) => {
    sendGAEvent(getGABeginCheckoutEvent(plan));
  };

  watchDebounced(
    () => plansToBeSentToGtmMap.value,
    () => sendGoogleAnalyticsEventsBatch(),
    { debounce: 50, deep: true },
  );

  return {
    shouldSendGAEvents,
    sendItemShownEvents,
    sendGAAddToCartEvent,
    sendGASelectEvent,
    sendGAViewCartEvent,
    sendGABeginCheckoutEvent,
  };
};
